.appointmentSlotsContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 0 0 1rem;
  background-color: white;
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2) inset;
  margin-bottom: 1rem;
  max-height: inherit;
  position: relative;
}

.colorLegendsContainer {
  display: flex;
  align-items: end;
  max-width: 400px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  box-shadow: 0 2px 1rem rgba(0, 0, 0, 0.1);
  border-radius: 1rem;
}

.loadingContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px;
  width: 1040px;
  height: 513px;
}

.monthsHeader {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 4px 8px;
  gap: 10px;
  width: 100%;
  height: 23px;
  background: #141414;
}

.monthsHeaderText {
  width: 103px;
  height: 15px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 150%;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #ffffff;
}

.slotsContainer {
  width: 100%;
  height: 100%;
  overflow: auto;
}
.calContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 16px;
  position: absolute;
  width: 1376px;
  height: 433px;
  left: -61px;
  top: 1px;
}

.daysContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 1rem;
  width: 1376px;
}

.day {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px 0px 16px;
  gap: 4px;
  /* width: 6rem; */
}

.dayHeader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px;
  width: 6.5rem;
  height: 73px;
}

.dayHeaderMonthText {
  height: 36px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 36px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #141414;
}

.dayHeaderDayTextContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  height: 21px;
}

.dayHeaderDayTextWeekday {
  height: 21px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  display: flex;
  align-items: center;
  color: #141414;
}

.slotContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 0.25rem;
}

.slotCard {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem 1rem;
  width: 100%;
  min-width: max-content;
  cursor: pointer;
  border: 1px solid #c2c2c2;
  border-radius: 0.25rem;
  user-select: none;
  transition: all 0.1s;
}

.slotCard:hover {
  /* filter: brightness(120%); */
  box-shadow: 0px 0px 0.25rem rgba(0, 0, 0, 0.7);
}

.slotCard.strikeOut {
  text-decoration: line-through;
}

.slotCard.disabled {
  cursor: not-allowed;
  box-shadow: none;
  opacity: 0.5;
  box-shadow: 0px 0px 0.25rem rgba(0, 0, 0, 0.15) inset;
}

.slotCard.loading {
  cursor: not-allowed;
  pointer-events: none;
  box-shadow: none;
  opacity: 0.6;
}
.slotCard.selected {
  filter: brightness(120%);
  box-shadow: 0px 0px 0.5rem rgba(0, 0, 0, 0.5);
}

.slotCard.previous {
  border-color: #2a7187;
}

.slotCard.previous::after {
  content: 'CURRENT';
  position: absolute;
  top: -0.5rem;
  translate: translateX(-50%);
  font-size: 9px;
  background: #2a7187;
  color: white;
  font-weight: 700;
  padding: 0.1rem 0.25rem;
  border-radius: 0.25rem;
  box-shadow: 0 0 0.25rem 0 rgba(0, 0, 0, 0.6);
}

.slotCard.unavailable {
  background-color: #d70000;
  cursor: not-allowed;
}

.slotCard.unavailable::after {
  content: 'UNAVAILABLE';
  position: absolute;
  top: -0.5rem;
  translate: translateX(-50%);
  font-size: 9px;
  background-color: #d70000;
  color: white;
  font-weight: 700;
  padding: 0.1rem 0.25rem;
  border-radius: 0.25rem;
  box-shadow: 0 0 0.25rem 0 rgba(0, 0, 0, 0.6);
}

.slotCard.disabled > .slotCardTimeRange {
  font-weight: 400;
}

.slotCardWindow {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 150%;
  display: flex;
  align-items: center;
  color: #141414;
}

.slotCardTimeRange {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 150%;
  display: flex;
  align-items: center;
}

.line {
  width: 409px;
  height: 0px;
  border: 1px solid #c2c2c2;
  transform: rotate(90deg);
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.overlay .progressContainer {
  text-align: center;
}

.drivingDetailsContainer {
  display: grid;
  width: 100%;
  margin: 1rem 0 0;
}

.drivingDetailsMessage {
  font-size: 24px;
  font-weight: 600;
  padding: 0.5rem 2rem;
  border: thin solid #c2c2c2;
  justify-self: center;
  background-color: #141414;
  color: white;
  border-radius: 0.25rem;
}

@media (max-width: 1023px) {
  .appointmentSlotsContainer {
    width: 100%;
  }
  .loadingContainer {
    width: 100%;
  }
  .monthsHeader {
    width: 100%;
  }
  .slotsContainer {
    width: 100%;
  }
}
@media (max-width: 650px) {
  .appointmentSlotsContainer {
    height: 100%;
  }
  .dayHeader {
    width: 100%;
  }
  .daysContainer {
    flex-direction: column;
    width: 100%;
  }
  .day {
    width: 100%;
    height: 100%;
  }
  .slotContainer {
    flex-direction: row;
    width: 300px;
    height: 100%;
    flex-wrap: wrap;
  }
}
