/* slot-colors-legend.module.css */

.slotColorsLegend {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 12px;
  border-bottom-left-radius: 8px;
  /* border-top-right-radius: 0; */
  width: fit-content;
  /* max-width: 400px; */
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  background-color: white;
}

.slotColorsLegendTitle {
  font-size: 18px;
  font-weight: bold;
  margin: 0;
  text-align: center;
  padding: 0 1rem;
}

.slotColorsLegendItem {
  display: flex;
  align-items: center;
  gap: 12px;
  background: white;
  padding: 10px;
  border-radius: 6px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  border-left: 6px solid transparent; /* Helps reinforce the color meaning */
}

.slotColorsLegendItemColor {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  flex-shrink: 0;
  border: 2px solid rgba(0, 0, 0, 0.1);
}

.slotColorsLegendItemText {
  font-size: 14px;
  font-weight: bold;
  color: #333;
  flex-grow: 1;
}

.slotColorsLegendItemDescription {
  font-size: 12px;
  color: #555;
  flex-grow: 1;
  display: none;
}

.slotColorsLegend:hover .slotColorsLegendItemDescription {
  display: block;
}

/* Add border accent colors based on background */
.slotColorsLegendItem:nth-child(2) {
  border-left-color: #1ec940;
}

.slotColorsLegendItem:nth-child(3) {
  border-left-color: yellow;
}

.slotColorsLegendItem:nth-child(4) {
  border-left-color: #d70000;
}
